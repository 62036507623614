import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.page.html',
  styleUrls: ['./viewdetails.page.scss'],
})
export class ViewdetailsPage implements OnInit {
  @Input() det;

  constructor() { 
    
  }

  ngOnInit() {

    console.log(this.det);
  }

}
