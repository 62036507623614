import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-viewstatushistory',
  templateUrl: './viewstatushistory.page.html',
  styleUrls: ['./viewstatushistory.page.scss'],
})
export class ViewstatushistoryPage implements OnInit {
  @Input() det;
  isLoading = false;
  stats = [];

  constructor(private http:HttpClient,private alertcontroller:AlertController) {
   
   }

  private load(){
    this.isLoading = true;
    this.http.get(environment.api_url + `Itcwaybill/get_status_history/${this.det.tdid}`).subscribe((res:any)=>{
      this.stats = res;
      this.isLoading = false;
    },(err)=>{
      this.isLoading = true;
    });
  }

  ngOnInit() {
    this.load();
  }

}
